<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="50%">
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-card-text>
            <div v-if="show == 'newDoc'">
              <v-form ref="formSesionNewDoc">
                <v-container>
                  <v-layout>
                    <v-flex xs12>
                      <v-file-input
                        outlined
                        @change="copyName"
                        v-model="fileUploaded"
                        accept="image/*,audio/*,video/*,text/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                        label="Haz clic para seleccionar el fichero a subir"
                      ></v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-if="show == 'sendDoc'">
              <v-form ref="formSesionSendDoc">
                <v-container>
                  <v-layout>
                    <v-flex xs12>
                      <v-select
                        label="Asociar a productores"
                        outlined
                        v-model="selectClient"
                        :items="this.$store.state.productor"
                        item-text="nombre"
                        item-value="id"
                        multiple
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'cargaCSV'">
              <v-form ref="formSesionCargaCSV">
                <v-container>
                  <v-layout>
                    <v-flex xs12>
                      <v-file-input
                        outlined
                        v-model="fileUploadedCSV"
                        accept=".csv"
                        label="Haz clic para seleccionar el fichero a subir"
                      ></v-file-input>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-switch
                        v-model="switchCreaCliente"
                        label="Crear productor en caso de que no exista"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'newLand'">
              <v-form ref="formNewLand">
                <v-container>
                  <v-layout>
                    <v-flex xs4>
                      <v-text-field
                        label="Nombre de la finca"
                        v-model="nombreFinca"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field
                        label="Identificador"
                        v-model="identificador"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Superficie (Ha)"
                        v-model="superficie"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-text-field
                        label="Plantas"
                        v-model="plantas"
                        required
                        outlined
                        type="number"
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Variedad"
                        v-model="variedad"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Polígono"
                        v-model="poligono"
                        required
                        outlined
                        type="number"
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Parcela"
                        v-model="parcela"
                        required
                        outlined
                        type="number"
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Recinto"
                        v-model="recinto"
                        required
                        outlined
                        type="number"
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-select
                        label="Provincia"
                        v-model="provincia"
                        :items="provincias"
                        item-text="nm"
                        item-value="id"
                        required
                        outlined
                        @change="loadMunicipios"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        label="Localidad"
                        v-model="localidad"
                        :items="municipios"
                        item-text="nm"
                        item-value="nm"
                        required
                        outlined
                        :disabled="provincia == null"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'editLand'">
              <v-form ref="formEditLand">
                <v-container>
                  <v-layout>
                    <v-flex xs4>
                      <v-text-field
                        label="Nombre de la finca"
                        v-model="nombreFincaEditar"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field
                        label="Identificador"
                        v-model="identificadorEditar"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Superficie (Ha)"
                        v-model="superficieEditar"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-text-field
                        label="Plantas"
                        v-model="plantasEditar"
                        required
                        outlined
                        type="number"
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Variedad"
                        v-model="variedadEditar"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Polígono"
                        v-model="poligonoEditar"
                        required
                        outlined
                        type="number"
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Parcela"
                        v-model="parcelaEditar"
                        required
                        outlined
                        type="number"
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Recinto"
                        v-model="recintoEditar"
                        required
                        outlined
                        type="number"
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-select
                        label="Provincia"
                        v-model="provinciaEditar"
                        :items="provincias"
                        item-text="nm"
                        item-value="id"
                        required
                        outlined
                        @change="loadMunicipios"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        label="Localidad"
                        v-model="localidadEditar"
                        :items="municipios"
                        item-text="nm"
                        item-value="nm"
                        required
                        outlined
                        :disabled="provinciaEditar == null"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'editInput'">
              <v-form ref="formEditInput">
                <v-container>
                  <v-layout>
                    <v-flex xs3>
                      <v-menu
                        v-model="menuDateEntrada"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            v-model="dateFormattedEntrada"
                            label="Fecha de entrada"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('fechaEntrada')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="fechaEntrada"
                          outlined
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Kilos"
                        v-model="kilosEntrada"
                        required
                        outlined
                        min="0"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Rendimiento"
                        v-model="rendimientoEntrada"
                        required
                        outlined
                        min="0"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Acidez / Humedad"
                        v-model="acidezEntrada"
                        required
                        outlined
                        min="0"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-text-field
                        label="Descuento"
                        v-model="descuentoEntrada"
                        required
                        outlined
                        min="0"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Albaran"
                        v-model="albaranEntrada"
                        required
                        outlined
                        min="0"
                        step="1"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-text-field
                        label="Comentario"
                        v-model="comentarioEntrada"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'editProduct'">
              <v-form ref="formEditProduct">
                <v-container>
                  <v-layout>
                    <v-flex xs3>
                      <v-select
                        v-model="tipoEditarProducto"
                        :items="productos"
                        label="Tipo de producto"
                        required
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Nombre del producto"
                        v-model="nombreEditarProducto"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Categoría del producto"
                        v-model="categoriaEditarProducto"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'editLiquidation'">
              <v-form ref="formEditLiquidation">
                <v-container>
                  <v-layout>
                    <v-flex xs4>
                      <v-text-field
                        v-model="productoEditarLiquidacion"
                        label="Tipo de producto"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs5>
                      <v-text-field
                        v-model="categoriaEditarLiquidacion"
                        label="Categoría"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="campanaEditarLiquidacion"
                        label="Campaña"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-text-field
                        label="Kilos"
                        v-model="kilosEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Precio"
                        v-model="precioEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Escandallo"
                        v-model="escandalloEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-select
                        label=""
                        v-model="tipoescandalloEditarLiquidacion"
                        outlined
                        :items="tiposescandallo"
                        item-text="name"
                        item-value="value"
                        @change="calculaTotalEditarLiquidacion"
                      ></v-select>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="IVA"
                        v-model="ivaEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="IRPF"
                        v-model="irpfEditarLiquidacion"
                        type="number"
                        min="0"
                        required
                        outlined
                        @change="calculaTotalEditarLiquidacion"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs4>
                      <v-text-field
                        v-model="totalEditarLiquidacion"
                        label="Total"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'editPrice'">
              <v-form ref="formEditPrice">
                <v-container>
                  <v-layout>
                    <v-flex xs3>
                      <v-text-field
                        v-model="productoEditarPrecio"
                        label="Tipo de producto"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="categoriaEditarPrecio"
                        label="Categoría"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        v-model="campanaEditarPrecio"
                        label="Campaña"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-text-field
                        label="Escandallo"
                        v-model="escandalloEditarPrecio"
                        type="number"
                        min="0"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Precio"
                        v-model="precioEditarPrecio"
                        type="number"
                        min="0"
                        required
                        outlined
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-model="estadoEditarPrecio"
                        :items="[
                          { name: 'Activado', value: 1 },
                          { name: 'Desactivado', value: 0 },
                        ]"
                        item-text="name"
                        item-value="value"
                        label="Estado"
                        required
                        outlined
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'signature'">
              <v-container>
                <v-layout>
                  <v-flex xs6>
                    <v-text-field
                      v-model="signatureKey"
                      label="Contraseña del trabajador"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPass ? 'text' : 'password'"
                      @click:append="showPass = !showPass"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <div v-else-if="show == 'schedule'">
              <v-expansion-panels focusable>
                <v-expansion-panel key="nuevoHorario">
                  <v-expansion-panel-header>
                    Definir horarios
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form ref="formDefineHorario">
                      <v-layout>
                        <v-flex xs7>
                          <v-select
                            v-model="defineHorario"
                            :items="horarios"
                            item-text="nombre"
                            item-value="id"
                            label="Nuevo horario"
                            required
                            outlined
                            style="margin: 1em"
                            @change="loadSchedule"
                          ></v-select>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex xs4>
                          <v-text-field
                            label="Nombre del horario"
                            v-model="dh.nombre"
                            type="text"
                            style="margin: 1em"
                            required
                            outlined
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada lunes mañana"
                            v-model="dh.lunesEM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida lunes mañana"
                            v-model="dh.lunesSM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada lunes tarde"
                            v-model="dh.lunesET"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida lunes tarde"
                            v-model="dh.lunesST"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada martes mañana"
                            v-model="dh.martesEM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida martes mañana"
                            v-model="dh.martesSM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada martes tarde"
                            v-model="dh.martesET"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida martes tarde"
                            v-model="dh.martesST"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada miércoles mañana"
                            v-model="dh.miercolesEM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida miércoles mañana"
                            v-model="dh.miercolesSM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada miércoles tarde"
                            v-model="dh.miercolesET"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida miércoles tarde"
                            v-model="dh.miercolesST"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada jueves mañana"
                            v-model="dh.juevesEM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida jueves mañana"
                            v-model="dh.juevesSM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada jueves tarde"
                            v-model="dh.juevesET"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida jueves tarde"
                            v-model="dh.juevesST"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada viernes mañana"
                            v-model="dh.viernesEM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida viernes mañana"
                            v-model="dh.viernesSM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada viernes tarde"
                            v-model="dh.viernesET"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida viernes tarde"
                            v-model="dh.viernesST"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada sábado mañana"
                            v-model="dh.sabadoEM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida sábado mañana"
                            v-model="dh.sabadoSM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada sábado tarde"
                            v-model="dh.sabadoET"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida sábado tarde"
                            v-model="dh.sabadoST"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada domingo mañana"
                            v-model="dh.domingoEM"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida domingo mañana"
                            v-model="dh.domingo"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Entrada domingo tarde"
                            v-model="dh.domingoET"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            label="Salida domingo tarde"
                            v-model="dh.domingoST"
                            type="time"
                            required
                            outlined
                            @blur="checkSchedule"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-btn class="btnCustom save" @click="saveSchedule"
                          ><v-icon class="btnIcon">mdi-content-save</v-icon>
                          Guardar</v-btn
                        >
                        <v-btn
                          class="btnCustom delete"
                          :disabled="this.defineHorario == null"
                          @click="deleteSchedule"
                          ><v-icon class="btnIcon">mdi-delete</v-icon>
                          Eliminar</v-btn
                        >
                      </v-layout>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel key="asignarHorario">
                  <v-expansion-panel-header>
                    Asignar horario a personal
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-select
                      v-model="idHorario"
                      :items="horarios"
                      item-text="nombre"
                      item-value="id"
                      label="Horario para asignar"
                      required
                      outlined
                      style="margin: 1em"
                      @change="printSchedule"
                    ></v-select>
                    <v-data-table
                      hide-default-footer
                      :headers="[
                        { text: 'Lunes', value: 'lunes' },
                        { text: 'Martes', value: 'martes' },
                        { text: 'Miércoles', value: 'miercoles' },
                        { text: 'Jueves', value: 'jueves' },
                        { text: 'Viernes', value: 'viernes' },
                        { text: 'Sábado', value: 'sabado' },
                        { text: 'Domingo', value: 'domingo' },
                      ]"
                      :items="horarioAsignar"
                      :items-per-page="1"
                    ></v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-else-if="show == 'notice'">
              <h2 style="text-align: center">{{ noticeTitle }}</h2>
              <v-img
                class="white--text align-end"
                style="
                  max-width: 300px;
                  margin-top: 2em;
                  margin-bottom: 2em;
                  margin-left: auto;
                  margin-right: auto;
                "
                :src="noticeImage"
              >
              </v-img>
              <strong>{{ noticeResume }}</strong>
              <div v-html="noticeText"></div>
            </div>
            <div v-else-if="show == 'preFormatted'">
              <pre>{{ text }}</pre>
            </div>
            <div v-else>{{ text }}</div>
          </v-card-text>
          <v-card-actions v-if="show != 'notice'">
            <v-spacer></v-spacer>
            <v-btn color="red" text @click.native="cancel">Cancelar</v-btn>
            <v-btn color="green" text @click.native="agree">Aceptar</v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click.native="cancel">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <showMessage ref="snackbar" />
  </div>
</template>
<script>
import { BASE_URL } from "../components/constants.js";
import { provincias, municipios } from "../components/constants.js";
import * as basics from "../components/basics.js";
import showMessage from "../components/Messages.vue";
import genericReload from "../components/genericReload";
export default {
  data() {
    return {
      dialog: false,
      title: "",
      text: "",
      show: 0,
      resolve: null,
      reject: null,

      selectClient: null,
      nombreNewDoc: "",
      fileUploaded: undefined,
      fileUploadedCSV: undefined,
      switchCreaCliente: false,
      identificador: "",
      nombreFinca: "",
      superficie: "",
      plantas: "",
      variedad: "",
      poligono: "",
      parcela: "",
      recinto: "",
      localidad: null,
      provincia: null,
      identificadorEditar: "",
      nombreFincaEditar: "",
      superficieEditar: "",
      plantasEditar: "",
      variedadEditar: "",
      poligonoEditar: "",
      parcelaEditar: "",
      recintoEditar: "",
      localidadEditar: null,
      provinciaEditar: null,
      provincias: provincias,
      municipios: [],
      productos: ["Frutos secos", "Frutales"],
      tipoEditarProducto: null,
      nombreEditarProducto: "",
      categoriaEditarProducto: "",
      productoEditarPrecio: "",
      categoriaEditarPrecio: "",
      campanaEditarPrecio: "",
      escandalloEditarPrecio: "",
      precioEditarPrecio: "",
      estadoEditarPrecio: 1,
      menuDateEntrada: "",
      dateFormattedEntrada: "",
      fechaEntrada: "",
      kilosEntrada: "",
      rendimientoEntrada: "",
      acidezEntrada: "",
      albaranEntrada: "",
      comentarioEntrada: "",
      descuentoEntrada: "",
      productoEditarLiquidacion: "",
      categoriaEditarLiquidacion: "",
      campanaEditarLiquidacion: "",
      kilosEditarLiquidacion: "",
      precioEditarLiquidacion: "",
      escandalloEditarLiquidacion: "",
      tipoescandalloEditarLiquidacion: "euro",
      tiposescandallo: [
        { name: "€", value: "euro" },
        { name: "%", value: "porcentaje" },
      ],
      ivaEditarLiquidacion: "",
      irpfEditarLiquidacion: "",
      totalEditarLiquidacion: "",
      noticeTitle: "",
      noticeResume: "",
      noticeText: "",
      noticeImage: "",
      idHorario: null,
      horario: null,
      horarioAsignar: [],
      horarios: [],
      defineHorario: null,
      dh: [],
      signatureKey: "",
      showPass: false,
    };
  },
  components: {
    showMessage,
  },
  mounted() {
    this.$refs.formSesionNewDoc?.reset();
    this.$refs.formSesionSendDoc?.reset();
    this.$refs.formSesionCargaCSV?.reset();
    this.$refs.formEditProduct?.reset();
    this.$refs.formEditPrice?.reset();
    this.$refs.formNewLand?.reset();
    this.$refs.formEditLand?.reset();
    this.$refs.formEditInput?.reset();
    this.$refs.formEditLiquidation?.reset();
    this.$refs.formDefineHorario?.reset();
    this.noticeTitle = "";
    this.noticeResume = "";
    this.noticeText = "";
    this.noticeImage = "";
    this.signatureKey = "";
  },
  methods: {
    setNew(title, text, show = 0, data) {
      this.dialog = true;
      this.title = title;
      this.text = text;
      this.show = show;
      if (show == "editProduct") {
        this.tipoEditarProducto = data[0];
        this.nombreEditarProducto = data[1];
        this.categoriaEditarProducto = data[2];
      }
      if (show == "editPrice") {
        this.productoEditarPrecio = data[0];
        this.categoriaEditarPrecio = data[1];
        this.campanaEditarPrecio = data[2];
        this.escandalloEditarPrecio = data[3];
        this.precioEditarPrecio = data[4];
        this.estadoEditarPrecio = data[5];
      }
      if (show == "editLand") {
        this.identificadorEditar = data[0];
        this.nombreFincaEditar = data[1];
        this.superficieEditar = data[2];
        this.plantasEditar = data[3];
        this.variedadEditar = data[4];
        this.poligonoEditar = data[5];
        this.parcelaEditar = data[6];
        this.recintoEditar = data[7];
        this.provinciaEditar = data[8];
        this.municipios = municipios.filter(
          (elem) => elem.id.substr(0, 2) == data[8]
        );
        setTimeout((this.localidadEditar = data[9]), 100);
      }
      if (show == "editInput") {
        this.dateFormattedEntrada = data.fecha;
        this.fechaEntrada = basics.formatDate(data.fecha, "yyyy-mm-dd", "/");
        this.kilosEntrada = data.kilos;
        this.rendimientoEntrada = data.rendimiento;
        this.descuentoEntrada = data.descuento;
        this.acidezEntrada = data.acidez;
        this.albaranEntrada = data.albaran;
        this.comentarioEntrada = data.comentario;
        this.changeDateFormatted("fechaEntrada");
      }
      if (show == "editLiquidation") {
        this.productoEditarLiquidacion = data.valorproducto;
        this.categoriaEditarLiquidacion = data.calidad;
        this.campanaEditarLiquidacion = data.campana;
        this.kilosEditarLiquidacion = data.kilos;
        this.precioEditarLiquidacion = data.precio;
        this.escandalloEditarLiquidacion = data.valorescandallo;
        this.tipoescandalloEditarLiquidacion = data.tipoescandallo;
        this.ivaEditarLiquidacion = data.iva;
        this.irpfEditarLiquidacion = data.irpf;
        this.totalEditarLiquidacion = data.total;
      }
      if (show == "schedule") {
        this.horarios = data[1];
        if (data[0]) {
          this.horario = data[0];
          this.printSchedule();
        }
      }
      if (show == "notice") {
        this.noticeTitle = data.titulo;
        this.noticeResume = data.resumen;
        this.noticeText = data.cuerpo;
        this.noticeImage = data.imagen;
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      switch (this.show) {
        case "newDoc":
          this.resolve({
            response: true,
            file: this.fileUploaded,
            name: this.nombreNewDoc,
            productor: this.selectClient,
          });
          this.$refs.formSesionNewDoc.reset();
          break;

        case "sendDoc":
          this.resolve({
            response: true,
            productor: this.selectClient,
          });
          this.$refs.formSesionSendDoc.reset();
          break;

        case "cargaCSV":
          this.resolve({
            response: true,
            file: this.fileUploadedCSV,
            crea: this.switchCreaCliente,
          });
          this.$refs.formSesionCargaCSV.reset();
          break;

        case "newLand":
          this.resolve({
            response: true,
            nombre: this.nombreFinca,
            identificador: this.identificador,
            superficie: this.superficie,
            plantas: this.plantas,
            variedad: this.variedad,
            poligono: this.poligono,
            parcela: this.parcela,
            recinto: this.recinto,
            localidad: this.localidad,
            provincia: this.provincia,
          });
          this.$refs.formNewLand.reset();
          break;

        case "editLand":
          this.resolve({
            response: true,
            nombre: this.nombreFincaEditar,
            identificador: this.identificadorEditar,
            superficie: this.superficieEditar,
            plantas: this.plantasEditar,
            variedad: this.variedadEditar,
            poligono: this.poligonoEditar,
            parcela: this.parcelaEditar,
            recinto: this.recintoEditar,
            provincia: this.provinciaEditar,
            localidad: this.localidadEditar,
          });
          this.$refs.formEditLand.reset();
          break;

        case "editInput":
          this.resolve({
            response: true,
            fecha: this.fechaEntrada,
            kilos: this.kilosEntrada,
            rendimiento: this.rendimientoEntrada,
            acidez: this.acidezEntrada,
            albaran: this.albaranEntrada,
            comentario: this.comentarioEntrada,
            descuento: this.descuentoEntrada,
          });
          this.$refs.formEditInput.reset();
          break;

        case "editProduct":
          this.resolve({
            response: true,
            nombre: this.nombreEditarProducto,
            categoria: this.categoriaEditarProducto,
            producto: this.tipoEditarProducto,
          });
          this.$refs.formEditProduct.reset();
          break;

        case "editPrice":
          this.resolve({
            response: true,
            escandallo: this.escandalloEditarPrecio,
            precio: this.precioEditarPrecio,
            estado: this.estadoEditarPrecio,
          });
          this.$refs.formEditPrice.reset();
          break;

        case "editLiquidation":
          this.resolve({
            response: true,
            kilos: this.kilosEditarLiquidacion,
            precio: this.precioEditarLiquidacion,
            escandallo: this.escandalloEditarLiquidacion,
            tipoescandallo: this.tipoescandalloEditarLiquidacion,
            iva: this.ivaEditarLiquidacion,
            irpf: this.irpfEditarLiquidacion,
            total: this.totalEditarLiquidacion,
          });
          this.$refs.formEditLiquidation.reset();
          break;

        case "schedule":
          this.resolve({
            response: true,
            idHorario: this.idHorario,
          });
          this.$refs.formDefineHorario?.reset();
          this.horarioAsignar = [];
          this.idHorario = null;
          break;

        case "signature":
          this.resolve({
            response: true,
            password: this.signatureKey,
          });
          break;

        default:
          this.resolve({ response: true });
          break;
      }
      this.dialog = false;
    },
    cancel() {
      this.$refs.formSesionNewDoc?.reset();
      this.$refs.formSesionSendDoc?.reset();
      this.$refs.formSesionCargaCSV?.reset();
      this.$refs.formNewLand?.reset();
      this.$refs.formEditLand?.reset();
      this.$refs.formEditInput?.reset();
      this.$refs.formEditProduct?.reset();
      this.$refs.formEditPrice?.reset();
      this.$refs.formEditLiquidation?.reset();
      this.$refs.formDefineHorario?.reset();
      this.horarioAsignar = [];
      this.idHorario = null;
      this.noticeTitle = "";
      this.noticeResume = "";
      this.noticeText = "";
      this.signatureKey = "";
      this.resolve(false);
      this.dialog = false;
    },
    copyName() {
      if (this.fileUploaded) {
        this.nombreNewDoc = this.fileUploaded.name;
      }
    },
    loadMunicipios() {
      this.municipios = [];
      if (this.provincia != null) {
        this.municipios = municipios.filter(
          (elem) => elem.id.substr(0, 2) == this.provincia
        );
      }
      if (this.provinciaEditar != null) {
        this.municipios = municipios.filter(
          (elem) => elem.id.substr(0, 2) == this.provinciaEditar
        );
      }
    },
    changeDateFormatted(field) {
      switch (field) {
        case "fechaEntrada":
          if (this.fechaEntrada) {
            this.dateFormattedEntrada = basics.formatDate(
              this.fechaEntrada,
              "dd/mm/yyyy"
            );
          }
          break;
      }
    },
    calculaTotalEditarLiquidacion() {
      this.totalEditarLiquidacion =
        this.kilosEditarLiquidacion * this.precioEditarLiquidacion;
      if (this.tipoescandalloEditarLiquidacion == "euro") {
        if (this.escandalloEditarLiquidacion != "") {
          this.totalEditarLiquidacion =
            parseFloat(this.totalEditarLiquidacion) -
            parseFloat(this.escandalloEditarLiquidacion);
        }
      } else {
        if (this.escandalloEditarLiquidacion != "") {
          this.totalEditarLiquidacion =
            this.totalEditarLiquidacion -
            (this.totalEditarLiquidacion *
              parseFloat(this.escandalloEditarLiquidacion)) /
              100;
        }
      }
      if (this.ivaEditarLiquidacion != "") {
        this.totalEditarLiquidacion =
          this.totalEditarLiquidacion +
          (this.totalEditarLiquidacion *
            parseFloat(this.ivaEditarLiquidacion)) /
            100;
      }
      if (this.irpfEditarLiquidacion != "") {
        this.totalEditarLiquidacion =
          this.totalEditarLiquidacion -
          (this.totalEditarLiquidacion *
            parseFloat(this.irpfEditarLiquidacion)) /
            100;
      }
      this.totalEditarLiquidacion =
        Math.round(this.totalEditarLiquidacion * 100) / 100;
    },
    printSchedule() {
      this.horarioAsignar = [];
      let h;
      if (this.horario) {
        h = this.horario;
      }
      if (this.idHorario) {
        h = this.horarios.find((horario) => horario.id === this.idHorario);
      }
      this.horarioAsignar.push({
        lunes: h.lunesEM + " " + h.lunesSM + "\n" + h.lunesET + " " + h.lunesST,
        martes:
          h.martesEM + " " + h.martesSM + "\n" + h.martesET + " " + h.martesST,
        miercoles:
          h.miercolesEM +
          " " +
          h.miercolesSM +
          "\n" +
          h.miercolesET +
          " " +
          h.miercolesST,
        jueves:
          h.juevesEM + " " + h.juevesSM + "\n" + h.juevesET + " " + h.juevesST,
        viernes:
          h.viernesEM +
          " " +
          h.viernesSM +
          "\n" +
          h.viernesET +
          " " +
          h.viernesST,
        sábado:
          h.sábadoEM + " " + h.sábadoSM + "\n" + h.sábadoET + " " + h.sábadoST,
        domingo:
          h.domingoEM +
          " " +
          h.domingoSM +
          "\n" +
          h.domingoET +
          " " +
          h.domingoST,
      });
    },
    async getSchedules() {
      const response = await genericReload(this.$store.state.auth, `horario`);
      if (response.status === 200) {
        const json = await response.json();
        this.horarios = json.body;
      }
    },
    loadSchedule() {
      this.dh = this.horarios.find(
        (horario) => horario.id === this.defineHorario
      );
    },
    async saveSchedule() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.auth,
      };
      let response;
      const data = {
        nombre: this.dh.nombre,
        lunesEM: this.dh.lunesEM,
        lunesSM: this.dh.lunesSM,
        lunesET: this.dh.lunesET,
        lunesST: this.dh.lunesST,
        martesEM: this.dh.martesEM,
        martesSM: this.dh.martesSM,
        martesET: this.dh.martesET,
        martesST: this.dh.martesST,
        miercolesEM: this.dh.miercolesEM,
        miercolesSM: this.dh.miercolesSM,
        miercolesET: this.dh.miercolesET,
        miercolesST: this.dh.miercolesST,
        juevesEM: this.dh.juevesEM,
        juevesSM: this.dh.juevesSM,
        juevesET: this.dh.juevesET,
        juevesST: this.dh.juevesST,
        viernesEM: this.dh.viernesEM,
        viernesSM: this.dh.viernesSM,
        viernesET: this.dh.viernesET,
        viernesST: this.dh.viernesST,
        sabadoEM: this.dh.sabadoEM,
        sabadoSM: this.dh.sabadoSM,
        sabadoET: this.dh.sabadoET,
        sabadoST: this.dh.sabadoST,
        domingoEM: this.dh.domingoEM,
        domingoSM: this.dh.domingoSM,
        domingoET: this.dh.domingoET,
        domingoST: this.dh.domingoST,
      };
      if (this.defineHorario == null) {
        response = await fetch(`${BASE_URL}/horario`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
      } else {
        response = await fetch(`${BASE_URL}/horario/${this.defineHorario}`, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(data),
        });
      }
      switch (response.status) {
        case 200:
          this.$refs.snackbar.setNew(
            true,
            "Datos guardados correctamente",
            "Ok",
            "green"
          );
          this.$refs.formDefineHorario.reset();
          await this.getSchedules();
          break;
        default:
          this.$refs.snackbar.setNew(
            true,
            "ERROR. Datos no guardados",
            "Cerrar",
            "red"
          );
          break;
      }
    },
    deleteSchedule() {
      this.$refs.dialog
        .setNew(
          "Eliminar horario",
          "¿Estás seguro de querer eliminar este horario definido?",
          0
        )
        .then(async (confirm) => {
          if (confirm.response) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const response = await fetch(
              `${BASE_URL}/horario/${this.defineHorario}`,
              {
                method: "DELETE",
                headers: headers,
              }
            );
            switch (response.status) {
              case 200:
                this.$refs.snackbar.setNew(
                  true,
                  "Horario eliminado correctamente",
                  "Ok",
                  "green"
                );
                await this.getSchedules();
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. Horario no eliminado",
                  "Cerrar",
                  "red"
                );
                break;
            }
          }
        });
    },
    checkSchedule() {
      if (
        (this.dh.lunesEM && !this.dh.lunesSM) ||
        this.dh.lunesEM > this.dh.lunesSM
      ) {
        this.dh.lunesSM = this.dh.lunesEM;
      }
      if (
        (this.dh.martesEM && !this.dh.martesSM) ||
        this.dh.martesEM > this.dh.martesSM
      ) {
        this.dh.martesSM = this.dh.martesEM;
      }
      if (
        (this.dh.miercolesEM && !this.dh.miercolesSM) ||
        this.dh.miercolesEM > this.dh.miercolesSM
      ) {
        this.dh.miercolesSM = this.dh.miercolesEM;
      }
      if (
        (this.dh.juevesEM && !this.dh.juevesSM) ||
        this.dh.juevesEM > this.dh.juevesSM
      ) {
        this.dh.juevesSM = this.dh.juevesEM;
      }
      if (
        (this.dh.viernesEM && !this.dh.viernesSM) ||
        this.dh.viernesEM > this.dh.viernesSM
      ) {
        this.dh.viernesSM = this.dh.viernesEM;
      }
      if (
        (this.dh.sabadoEM && !this.dh.sabadoSM) ||
        this.dh.sabadoEM > this.dh.sabadoSM
      ) {
        this.dh.sabadoSM = this.dh.sabadoEM;
      }
      if (
        (this.dh.domingoEM && !this.dh.domingoSM) ||
        this.dh.domingoEM > this.dh.domingoSM
      ) {
        this.dhdomingoSM = this.dh.domingoEM;
      }
      if (
        (this.dh.lunesET && !this.dh.lunesST) ||
        this.dh.lunesET > this.dh.lunesST
      ) {
        this.dh.lunesST = this.dh.lunesET;
      }
      if (
        (this.dh.martesET && !this.dh.martesST) ||
        this.dh.martesET > this.dh.martesST
      ) {
        this.dh.martesST = this.dh.martesET;
      }
      if (
        (this.dh.miercolesET && !this.dh.miercolesST) ||
        this.dh.miercolesET > this.dh.miercolesST
      ) {
        this.dh.miercolesST = this.dh.miercolesET;
      }
      if (
        (this.dh.juevesET && !this.dh.juevesST) ||
        this.dh.juevesET > this.dh.juevesST
      ) {
        this.dh.juevesST = this.dh.juevesET;
      }
      if (
        (this.dh.viernesET && !this.dh.viernesST) ||
        this.dh.viernesET > this.dh.viernesST
      ) {
        this.dh.viernesST = this.dh.viernesET;
      }
      if (
        (this.dh.sabadoET && !this.dh.sabadoST) ||
        this.dh.sabadoET > this.dh.sabadoST
      ) {
        this.dh.sabadoST = this.dh.sabadoET;
      }
      if (
        (this.dh.domingoET && !this.dh.domingoST) ||
        this.dh.domingoET > this.dh.domingoST
      ) {
        this.dhdomingoST = this.dh.domingoET;
      }
    },
  },
};
</script>
<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
.v-input {
  margin-right: 1em;
}
.v-file-input {
  flex-direction: row-reverse;
}
</style>